"use client";
import { client } from "@llamaindex/cloud";
import { Button } from "@llamaindex/component/ui/button";
import Image from "next/image";
import Link from "next/link";

export default function OIDCLogin() {
  const handleSSOLogin = () => {
    window.location.href = `${client.getConfig().baseUrl}/api/v1/auth/login`;
  };

  return (
    <div className="flex size-full flex-col px-0 sm:px-12">
      <div className="relative flex h-screen w-full flex-1 items-center justify-center bg-white">
        <div className="relative flex items-center justify-center p-5 sm:min-w-[800px] sm:border sm:p-20">
          {[
            "top-0 left-0 -translate-x-1.5 -translate-y-1.5",
            "top-0 right-0 translate-x-1.5 -translate-y-1.5",
            "bottom-0 right-0 translate-x-1.5 translate-y-1.5",
            "bottom-0 left-0 -translate-x-1.5 translate-y-1.5",
          ].map((dir, index) => (
            <span className={`absolute hidden sm:block ${dir} `} key={index}>
              {plusIcon}
            </span>
          ))}
          <div className="relative z-20 flex flex-col gap-4 rounded-3xl bg-white p-4 pt-10 sm:p-6 sm:pt-14">
            {/* Logo container */}
            <div className="flex justify-center">
              <Image
                src="/assets/rounded_corners_logo.png"
                alt="LlamaIndex"
                width={80}
                height={80}
                className="h-12 w-auto rounded-lg"
              />
            </div>
            <div>
              <h2 className="mb-2 w-full text-center text-xl font-medium">
                Welcome to LlamaCloud
              </h2>
            </div>
            <Button
              variant="outline"
              className="flex w-full gap-2 whitespace-nowrap text-sm"
              onClick={handleSSOLogin}
            >
              Log in via SSO
            </Button>
            <p className="mt-4 text-center text-xs text-slate-400">
              By continuing, you are indicating that you accept our <br />{" "}
              <Link
                href="https://www.llamaindex.ai/files/terms-of-service.pdf"
                target="_blank"
                className="text-slate-500 hover:text-slate-600"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                href="https://www.llamaindex.ai/files/privacy-notice.pdf"
                target="_blank"
                className="text-slate-500 hover:text-slate-600"
              >
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </div>
        <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center">
          <svg
            width="601"
            height="601"
            fill="none"
            className="opacity-25 blur-[120px]"
          >
            <path fill="#AA9AFF" d="M601 0H0v601h601V0Z"></path>
            <mask
              id="callToActionA"
              width="601"
              height="601"
              x="0"
              y="0"
              maskUnits="userSpaceOnUse"
              style={{ maskType: "luminance" }}
            >
              <path fill="#fff" d="M601 0H0v601h601V0Z"></path>
            </mask>
            <g mask="url(#callToActionA)" opacity="0.8">
              <path
                fill="#8237DF"
                d="M507.845 101.043H38.313v372.996h469.532V101.043Z"
              ></path>
              <path
                fill="#02B8F3"
                d="M574.33 20.66H155.884v321.159H574.33V20.659Z"
              ></path>
              <path
                fill="#ED5FDD"
                d="M741.483 55.593H277.962v351.96h463.521V55.593Z"
              ></path>
              <path
                fill="#E3B8C2"
                d="M537.143 289.231H207.72v313.271h329.423V289.231Z"
              ></path>
            </g>
          </svg>
        </div>
      </div>
      <div className="flex w-full flex-row items-end justify-center gap-5 border-b-neutral-200 pb-3 text-sm">
        <Link
          href="https://www.llamaindex.ai"
          target="_blank"
          className="text-slate-500 hover:text-slate-600"
        >
          LlamaIndex
        </Link>
        <span className="text-slate-500">|</span>
        <Link
          href="https://www.llamaindex.ai/files/privacy-notice.pdf"
          target="_blank"
          className="text-slate-500 hover:text-slate-600"
        >
          Privacy Notice
        </Link>
        <span className="text-slate-500">|</span>
        <Link
          href="https://www.llamaindex.ai/files/terms-of-service.pdf"
          target="_blank"
          className="text-slate-500 hover:text-slate-600"
        >
          Terms of Service
        </Link>
      </div>
    </div>
  );
}

const plusIcon = (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1C7 0.447715 6.55228 0 6 0H5C4.44772 0 4 0.447715 4 1V3C4 3.55229 3.55228 4 3 4H1C0.447715 4 0 4.44772 0 5V6C0 6.55228 0.447715 7 1 7H3C3.55228 7 4 7.44772 4 8V10C4 10.5523 4.44772 11 5 11H6C6.55228 11 7 10.5523 7 10V8C7 7.44771 7.44772 7 8 7H10C10.5523 7 11 6.55228 11 6V5C11 4.44772 10.5523 4 10 4H8C7.44772 4 7 3.55228 7 3V1Z"
      fill="#ADA8C4"
    />
  </svg>
);
